import axios from "axios";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const Body = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const [token, setToken] = useState("");

  const handleSelectOrDragFile = (file) => {
    setError(null);
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const obj = JSON.parse(event.target.result);
      if (obj && obj["type"] === "service_account") {
        setFile({
          name: file.name,
          meta: {
            ...obj,
          },
        });
      } else {
        setToken("");
        setError({
          title: "Invalid JSON file. ",
          message: "It must be a firebase service account json file.",
        });
      }
    };
  };

  const handleGenerateToken = () => {
    if (!file) {
      setError({
        title: "Select file. ",
        message: "Please select firebase service account json file.",
      });
      return;
    }
    if (token) {
      return;
    }
    const meta = file?.meta;
    const firbase_functions_url =
      "https://asia-northeast1-fcm-oauth-token.cloudfunctions.net/getFcmOauthTokenApi";
    axios
      .post(firbase_functions_url, {
        client_email: meta.client_email,
        private_key: meta.private_key,
      })
      .then((response) => {
        const token = response.data;
        setToken(token);
      })
      .catch((error) => {
        setError({
          title: "Error ",
          message: error.message,
        });
      });
  };

  const handleCopy = () => {
    if (token) {
      navigator.clipboard.writeText(token);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <p className="text-gray-800 text-xl font-semibold text-center p-3">
          Get the secret service json file from firebase project  and get the oauth token
        </p>
      </div>
      <div className="flex justify-center items-center mt-10">
        <FileUploader
          handleChange={handleSelectOrDragFile}
          name="file"
          types={["json"]}
          maxSize={1}
        >
          <div className="w-96 p-6 border-4 border-dashed border-gray-400 rounded-lg">
            {!file ? (
              <div className="text-center">
                <p className="text-gray-500 text-lg font-medium">
                  Drag and drop your file here
                </p>
                <p className="text-gray-400 mt-2">
                  Supported file type: <span className="font-bold">.json</span>
                </p>
              </div>
            ) : (
              <div className="text-center">
                <p className="text-gray-500 text-lg font-medium">
                  Selected File:
                </p>
                <div className="mt-2 p-4 bg-gray-50 border border-gray-300 rounded-lg">
                  <span className="text-gray-700 font-semibold">
                    {file.name}
                  </span>
                </div>
              </div>
            )}
          </div>
        </FileUploader>
      </div>
      <div className="flex justify-center items-center mt-10">
        <button
          className="p-4 my-4 bg-yellow-500 rounded-lg w-3/12 text-white "
          onClick={handleGenerateToken}
        >
          <span className="font-bold">{"Generate"}</span>
        </button>
      </div>
      {error && (
        <div
          className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 w-5/12 mx-auto mt-10"
          role="alert"
        >
          <span className="font-medium">{error?.title}</span>
          {error?.message}
        </div>
      )}
      {token && (
        <div className="flex justify-center items-center mt-10">
          <div className="w-full max-w-6xl  p-4">
            <div className="flex">
              <h2 className="text-center text-xl font-semibold text-gray-700 mb-2">
                Bearer Token
              </h2>
              <button
                onClick={handleCopy}
                className="mx-auto mb-5 text-sm text-blue-500 border border-blue-500 px-2 py-1 rounded-md hover:bg-blue-500 hover:text-white focus:outline-none"
              >
                {copied ? "Copied!" : "Copy"}
              </button>
            </div>
            <div className="relative bg-gray-100 p-4">
              <div className="overflow-auto max-h-64 text-left break-all">
                <code className="text-gray-600">{token}</code>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Body;
