import React from "react";

const Header = () => {
  return (
    <div className="text-black p-4 flex justify-start">
      <h1 className="text-xl font-bold font-mono">FCM OAuth Token</h1>
    </div>
  );
};

export default Header;
